<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto mt-4">
        <div class="flex px-2">
          <span class="flex gap-x-5">
            <h1 class="font-bold text-4xl my-auto">
              {{ `${user.firstName} ${user.lastName}` }}
            </h1>
            <h3
              class="my-auto px-2 rounded-xl text-gray-800"
              :class="{
                'bg-blue-400': user.role === 'member',
                'bg-green-500': user.role === 'divisionLead',
                'bg-cyan-400': user.role === 'admin',
              }"
            >
              {{ user.formattedRole }}
            </h3>
          </span>
          <div class="ml-auto">
            <img :src="user.avatar" alt="" />
          </div>
        </div>
        <h3 class="text-xl px-2">{{ user.organization }}</h3>
        <!-- Projects -->
        <div id="projects" class="mt-4">
          <div
            class="
              font-bold
              text-3xl
              border-b-2 border-black
              mx-4
              text-center
              lg:text-left
              mb-8
              flex
              pb-2
            "
          >
            <span>Projects</span>
            <button
              v-if="$store?.state?.user?.id === user.id"
              type="button"
              class="
                ml-auto
                inline-flex
                items-center
                px-4
                py-2
                border border-transparent
                text-base
                font-medium
                rounded-md
                text-black
                hover:bg-gray-700 hover:text-white
                active:bg-black active:text-white
                focus:outline-none
              "
            >
              <PlusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Create Project
            </button>
          </div>
          <ul id="projectList" class="pl-2 lg:pl-8 mt-2">
            <li class="mb-16" v-for="project in projects" :key="project.id">
              <h4 class="bold font-serif font-bold text-lg lg:text-xl">
                {{ project.title }}
              </h4>
              <div class="mt-1 mb-3 font-serif font-bold">
                <span>{{ project.formattedStartDate }}</span>
                <span>{{ " - " }}</span>
                <span>{{ project.formattedEndDate }}</span>
              </div>
              <div class="pl-2">
                <div>{{ project.description }}</div>
              </div>

              <ul id="projectList" class="mt-2 flex flex-row flex-wrap gap-2">
                <li
                  v-for="member in project.members"
                  :key="member.id"
                  class="text-center"
                >
                  <div class="bg-cyan-100 rounded-2xl py-1 px-2">
                    <span>{{ member.firstName }}</span>
                    <span>{{ " " }}</span>
                    <span>{{ member.lastName }}</span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <!-- Skills -->
        <div id="skills" class="mt-4 pb-2">
          <div
            class="
              font-bold
              text-3xl
              border-b-2 border-black
              mx-4
              text-center
              lg:text-left
              mb-8
              flex
              pb-2
            "
          >
            <span>Skills</span>
            <button
              v-if="$store?.state?.user?.id === user.id"
              type="button"
              class="
                ml-auto
                inline-flex
                items-center
                px-4
                py-2
                border border-transparent
                text-base
                font-medium
                rounded-md
                text-black
                hover:bg-gray-700 hover:text-white
                active:bg-black active:text-white
                focus:outline-none
              "
            >
              <PlusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Add Skill
            </button>
          </div>
          <ul
            id="skillList"
            class="py-4 px-8 mt-2 flex flex-row flex-wrap gap-4"
          >
            <li v-for="skill in skills" :key="skill.id" class="text-center">
              <span class="bg-cyan-100 rounded-2xl py-1 px-2">{{ skill }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/solid";
import { get, formatDate } from "../helper";

export default {
  name: "Profile",
  props: ["divisionName", "divisionBio", "divisionHeroImage"],
  components: {
    PlusIcon,
  },
  data() {
    return {
      user: "",
      skills: [
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
        "C++",
        "Python",
        "Deep Learning",
        "Web Dev",
      ],
      projects: [
        {
          title:
            "Magna dolor anim minim minim laborum aliqua sunt labore ullamco ipsum magna enim qui.",
          startDate: 1621141200000,
          endDate: 1629090000000,
          description:
            "Laboris cillum consectetur voluptate ut enim aliqua anim irure sunt elit consectetur eiusmod. Est magna exercitation quis tempor aute veniam aute et laborum culpa nostrud. Elit ut nostrud aute in id laborum aute excepteur pariatur commodo qui.",
          members: [
            {
              firstName: "Zayn",
              lastName: "Wagstaff",
            },
            {
              firstName: "Kacie",
              lastName: "Dotson",
            },
            {
              firstName: "Antoine",
              lastName: "Guest",
            },
          ],
        },
        {
          title: "Dolore Lorem velit duis culpa irure voluptate ipsum.",
          startDate: 1621141200000,
          endDate: 1629090000000,
          description:
            "Ipsum officia laboris nisi sit laborum deserunt fugiat ad enim est occaecat culpa. Duis officia excepteur ut fugiat fugiat amet nulla reprehenderit. Laboris ipsum nulla quis pariatur enim mollit elit. Tempor non aute sunt officia voluptate pariatur non irure anim. Nostrud fugiat esse est pariatur amet qui sunt aliqua irure. Occaecat exercitation deserunt nostrud consequat nisi reprehenderit eiusmod laborum ullamco cupidatat cillum pariatur.",
          members: [
            {
              firstName: "Dottie",
              lastName: "Wilson",
            },
            {
              firstName: "Farhaan",
              lastName: "Parkes",
            },
            {
              firstName: "Arnie",
              lastName: "Lamb",
            },
          ],
        },
        {
          title:
            "Sint eiusmod cillum cillum pariatur officia fugiat tempor excepteur ut laboris non.",
          startDate: 1621141200000,
          endDate: 1629090000000,
          description:
            "Dolore adipisicing duis Lorem excepteur voluptate sit mollit enim ad et laboris aliqua excepteur consectetur. Excepteur in exercitation id ipsum duis commodo amet est exercitation. Eu nisi enim aliqua aliqua consectetur dolore eu commodo labore consectetur eiusmod et occaecat.",
          members: [
            {
              firstName: "Lois",
              lastName: "Sherman",
            },
            {
              firstName: "Kaitlan",
              lastName: "Mcdowell",
            },
            {
              firstName: "Adelaide ",
              lastName: "Mcintyre",
            },
          ],
        },
      ],
    };
  },
  methods: {
    setData(data) {
      this.user = data.profile;
      if (this.user.role === "member") {
        this.user.formattedRole = "Member";
      } else if (this.user.role === "admin") {
        this.user.formattedRole = "Admin";
      } else if (this.user.role === "divisonLead") {
        this.user.formattedRole = "Division Lead";
      }
      this.user.projects = this.projects;
      for (const project in this.user.projects) {
        this.user.projects[project].formattedStartDate = formatDate(
          this.user.projects[project].startDate
        );
        this.user.projects[project].formattedEndDate = formatDate(
          this.user.projects[project].endDate
        );
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    const res = await get(to.path);
    if (res.ok) {
      const data = await res.json();
      next((vm) => vm.setData(data));
    } else {
      next({ name: "NotFound" });
    }
  },
  async beforeRouteUpdate(to) {
    try {
      const res = await get(to.path);
      if (res.ok) {
        const data = await res.json();
        this.user = this.setData(data);
      } else {
        this.$router.push({ name: "NotFound" });
      }
    } catch (error) {
      this.error = error.toString();
    }
  },
};
</script>
